import React, { useEffect } from "react";
import styled from "styled-components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

export const StyledCard = styled.div`
	&& {
		width: 100%;
		height: 100%;
		position: relative;

		&:hover .overlay {
			opacity: 0.8;
			cursor: pointer;
		}
		.image {
			display: block;
			width: 100%;
			max-height: 230px;
			object-fit: cover;
			object-position: center;
			border-radius: 9px;
		}
		.overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			transition: 0.5s ease;
			background-color: #008cba;
			border-radius: 9px;
		}
		.text {
			margin: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			padding: 20px;
			max-width: 256px;
			width: 100%;
			transform: translate(-50%, -50%);
			text-align: center;
			color: white;
			h3 {
				text-transform: uppercase;
				font-weight: 500;
				font-size: 25px;
			}
			p {
				font-size: 20px;
				font-weight: 500;
			}
		}
	}
`;

export const Card = ({ name, title, url, ...props }) => {
	return (
		<StyledCard>
			<Image src={url} className="image" />
			<div className="overlay">
				<div className="text">
					<h3>{name}</h3>
					<p>{title}</p>
				</div>
			</div>
		</StyledCard>
	);
};

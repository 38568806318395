import React from "react";
import styled from "styled-components";

export const StyledInputText = styled.div`
	&& {
		height: 90px;
		position: relative;
		input {
			padding: 18px;
			border-radius: 6px;
			border: solid 2px;
			width: 100%;
		}
		.error-input {
			border-color: red;
		}
		p {
			position: absolute;
			bottom: 5px;
			color: red;
			margin: 0;
			font-size: 14px;
		}
	}
`;

export const InputTextFormik = ({ name, placeholder, formik, ...props }) => {
	return (
		<StyledInputText>
			<input
				type="text"
				id={name}
				name={name}
				onChange={formik.handleChange}
				placeholder={placeholder}
				{...props}
				className={
					formik &&
					formik.errors &&
					formik.errors[name] &&
					"error-input"
				}
			/>
			{formik && formik.errors && formik.errors[name] && (
				<p>{formik.errors[name]}</p>
			)}
		</StyledInputText>
	);
};

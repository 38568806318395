export const tabsContent = [
	{
		title: "All Projects",
		key: "all",
	},
	{
		title: "Housing",
		key: "housing",
	},
	{
		title: "Commercial",
		key: "commercial",
	},
	{
		title: "Urbanism",
		key: "urbanism",
	},
	{
		title: "Public Space",
		key: "public_space",
	},
	{
		title: "Public Building",
		key: "public_building",
	},
];

import React, { Fragment, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useFormik } from "formik";
import {
	GoogleReCaptchaProvider,
	GoogleReCaptcha,
} from "react-google-recaptcha-v3";

import { StyledContactPage } from "./index.style";
import { InputTextFormik } from "./component/text";
import { InputTextAreaFormik } from "./component/textarea";
import { ArrowRight } from "react-bootstrap-icons";
import Footer from "../../components/footer";
import HeaderNav from "../../components/headerNav";

function Contact() {
	const [token, setToken] = useState();
	const [serverState, setServerState] = useState({
		submitting: false,
		status: null,
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const formik = useFormik({
		initialValues: {
			name: "",
			message: "",
			email: "",
		},
		onSubmit: (values) => {
			// alert(JSON.stringify(values, null, 2));
			setServerState({ submitting: true });
		},
		validate: (values) => {
			const errors = {};
			if (!values.email) {
				errors.email = "Email must be filled";
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
			) {
				errors.email = "Invalid Email Format";
			}
			if (!values.name) {
				errors.name = "Name is required";
			}
			if (!values.message) {
				errors.message = "Message cannot be empty";
			}
			return errors;
		},
	});

	const handleServerResponse = (ok, msg, form) => {
		setServerState({
			submitting: false,
			status: { ok, msg },
		});
		if (ok) {
			form.reset();
		}
	};

	return (
		<Fragment>
			<HeaderNav />
			<StyledContactPage className="wrapper">
				<GoogleReCaptchaProvider reCaptchaKey="6Lcf2WggAAAAAExiByF7fN2HoWJLhkTggBdRaoQg">
					<form onSubmit={formik.handleSubmit}>
						<Container>
							<Row>
								<Col>
									<h3>Contact</h3>
									<h1>We'd excited to hear more from you</h1>
								</Col>
							</Row>
							<Row>
								<Col md={4}>
									<InputTextFormik
										name="name"
										placeholder="Name"
										formik={formik}
									/>
								</Col>
								<Col md={4}>
									<InputTextFormik
										name="email"
										placeholder="Email"
										formik={formik}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<InputTextAreaFormik
										name="message"
										placeholder="Message"
										formik={formik}
									/>
								</Col>
							</Row>
							<Row>
								<Col style={{ textAlign: "right" }}>
									<button className="submit">
										SUBMIT <ArrowRight />
									</button>
									<GoogleReCaptcha
										onVerify={(token) => {
											setToken(token);
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<p className="reach">
										Reach Us <br />
										<br />
										+62 853 74712006
										<br />
										contact@onedreamarchitects.com
										<br />
										IG: onedream.architects
									</p>
								</Col>
							</Row>
						</Container>
					</form>
				</GoogleReCaptchaProvider>
			</StyledContactPage>

			<Footer />
		</Fragment>
	);
}

export default Contact;

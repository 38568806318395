import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap-4-pagination";

import { StyledProjectList } from "./ProjectList.style";
import { ProjectCard } from "./components/ProjectCard";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_SERVER } from "../../config/constants";

const ProjectList = ({ type }) => {
	console.log(API_SERVER);
	const [data, setData] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [loading, setLoading] = useState(false);
	const totalPages = Math.ceil(data?.count / 9);

	let paginationConfig = {
		totalPages: totalPages,
		currentPage: activePage,
		showMax: 7,
		size: "md",
		color: "#2e2e2e",
		center: true,
		threeDots: true,
		prevNext: true,
		onClick: (page) => {
			setActivePage(page);
		},
	};

	useEffect(() => {
		if (type) {
			window.scrollTo(0, 0);
			handleGetProjects();
		}
	}, [type, activePage]);

	useEffect(() => {
		if (data) {
			setLoading(false);
		}
	}, [data]);

	const handleGetProjects = () => {
		setLoading(true);
		try {
			axios
				.get(API_SERVER + "projects", {
					params: {
						skip: (activePage - 1) * 9,
						take: 9,
						publishStatus: "active",
						category: type === "all" ? "" : type,
					},
				})
				.then((response) => {
					if (response.data.success) {
						setData(response.data);
					} else {
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		} catch (err) {}
	};

	return (
		<StyledProjectList>
			{loading ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					<Spinner animation="border" size="md" style={{}} />
				</div>
			) : (
				<div>
					<Row>
						{data &&
							data.data.map((atr) => (
								<Col
									xs={12}
									md={4}
									className="mb-4"
									key={atr.id}
								>
									<Link to={`/details/${atr.slug}`}>
										<ProjectCard {...atr} />
									</Link>
								</Col>
							))}
					</Row>
					<Row>
						<Col>
							{totalPages > 1 && (
								<div style={{ width: "100%" }}>
									<Pagination
										{...paginationConfig}
										activeBgColor="#2e2e2e"
										activeBorderColor="#2e2e2e"
										disabled={loading}
									/>
								</div>
							)}
						</Col>
					</Row>
				</div>
			)}
		</StyledProjectList>
	);
};

export default ProjectList;

import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useRoutes,
} from "react-router-dom";

import { PublicRoute, PublicRoutes } from "./routes";
import routesSchema, { elementsSchema } from "./routes/schema";
import Page500 from "./pages/Error/500";
import Page404 from "./pages/Error/404";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import "./App.css";

function App() {
	return (
		<div className="app">
			<azara-bot
				origin="https://prod.azara.ai"
				api-url="https://prod.azara-ai.com"
				api-key="e289e3a71dbd87ae5a2e65d227d1fd3cd41ce250eea6b43bfa744bc701d5cafd4d5ad7fad552a84ea5fef4a00e92ee79"
				widget-id="be16b82d-8033-4719-b83b-402de61c1487"
				tenant-id="oda"
				agent-id="f4c1b144-026d-454e-a94d-75d4c941b59e"
			></azara-bot>
			<Router>
				<PublicRoutes />
			</Router>
		</div>
	);
}

export default App;

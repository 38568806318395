import React, { Fragment, useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import ProjectList from "./ProjectList";
import { StyledProjectsPage } from "./index.style";

import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { tabsContent } from "./tabsContent";
import Footer from "../../components/footer";
import HeaderNav from "../../components/headerNav";

function Projects() {
	let navigate = useNavigate();
	let { category } = useParams();

	const [tab, setTab] = useState("all");

	useEffect(() => {
		if (category) {
			setTab(category);
		} else {
			navigate(`/projects/all`, { replace: true });
		}
	}, [category]);

	const handleNavigate = (e) => {
		navigate(`/projects/${e}`, { replace: true });
	};

	return (
		<Fragment>
			<HeaderNav />
			<StyledProjectsPage className="wrapper">
				<Container>
					<Tab.Container
						id="left-tabs-example"
						defaultActiveKey="first"
						className="tab-container"
					>
						<Row>
							<Col sm={12}>
								<Nav
									transition={true}
									activeKey={tab}
									onSelect={(k) => handleNavigate(k)}
								>
									{tabsContent.map((e) => {
										return (
											<Nav.Item
												key={e.key}
												className={`${
													e.key === tab && "activate"
												} ${e.key}`}
											>
												<Nav.Link eventKey={e.key}>
													{e.title}
												</Nav.Link>
											</Nav.Item>
										);
									})}
								</Nav>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<Tab.Content>
									<ProjectList type={category}></ProjectList>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Container>
			</StyledProjectsPage>
			<Footer />
		</Fragment>
	);
}

export default Projects;

import React, { useEffect, useState, Fragment } from "react";
import Spinner from "react-bootstrap/Spinner";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import axios from "axios";
import { API_SERVER, API_SERVER_PLAIN } from "../../../config/constants";

export const StyledCarousel = styled.div`
	&& {
		border-top: 1px #d9d9d9 solid;
		margin-top: 100px;
		padding-top: 30px;
	}
`;

function DetailSlides({ id }) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id) {
			handleGetProjectSlides();
		}
	}, [id]);
	useEffect(() => {
		if (data) {
			setLoading(false);
		}
	}, [data]);

	const handleGetProjectSlides = () => {
		setLoading(true);
		try {
			axios
				.get(API_SERVER + "projects/details/slides/" + id)
				.then((response) => {
					if (response.data.success) {
						setData(response.data.data);
					} else {
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		} catch (err) {}
	};

	return (
		<Fragment>
			{loading && !data ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					<Spinner animation="border" size="md" style={{}} />
				</div>
			) : (
				<StyledCarousel>
					<Carousel>
						{data &&
							data.map((e) => (
								<Carousel.Item>
									<Image
										className="d-block w-100"
										src={`${API_SERVER_PLAIN}${e.path}`}
										key={e.id}
									/>
								</Carousel.Item>
							))}
					</Carousel>
				</StyledCarousel>
			)}
		</Fragment>
	);
}

export default DetailSlides;

import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";

import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Collapse from "react-bootstrap/Collapse";
import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Instagram, List, Envelope } from "react-bootstrap-icons";
import ScrollToTop from "react-scroll-to-top";

import styled from "styled-components";
import { getPath } from "../helper";

export const StyledHeaderNav = styled.div`
	&& {
		max-width: 245px;
		position: fixed;
		top: 0;
		right: 0;
		text-align: right;
		z-index: 20;
		.menu-icon {
			font-size: 50px;
			color: white;
			border-radius: 4px;
			background: rgba(0, 0, 0, 0.5);
			cursor: pointer;
			position: fixed;
			right: 30px;
			top: 30px;
			padding: 4px;
		}
		.logo {
			margin-top: 40px;
			margin-right: 40px;
			margin-bottom: 30px;
			width: 110px;
		}
		.nav-area {
			padding-right: 40px;
			min-width: 135px;
			width: 100%;
			font-size: 24px;
			font-weight: 500;
			text-align: right;
			color: white;
			button {
				text-align: right;
				background: none;
				border: none;
				font-size: 24px;
				font-weight: 500;
				margin: 0;
				padding: 0;
			}
			li {
				margin-bottom: 15px;
				.sub-nav {
					margin: 20px 0;
					font-weight: 300;
					font-size: 18px;
					.all {
						&.activate {
							color: #2e6783;
							border-bottom: solid 2px #2e6783;
						}
						&:hover {
							color: #2e6783;
							border-bottom: solid 2px #2e6783;
						}
					}

					.housing {
						&.activate {
							color: #eca924;
							border-bottom: solid 2px #eca924;
						}
						&:hover {
							color: #eca924;
							border-bottom: solid 2px #eca924;
						}
					}
					.commercial {
						&.activate {
							color: #ac49c4;
							border-bottom: solid 2px #ac49c4;
						}
						&:hover {
							color: #ac49c4;
							border-bottom: solid 2px #ac49c4;
						}
					}
					.public_space {
						&.activate {
							color: #b3c057;
							border-bottom: solid 2px #b3c057;
						}
						&:hover {
							color: #b3c057;
							border-bottom: solid 2px #b3c057;
						}
					}
					.public_building {
						&.activate {
							color: #67baca;
							border-bottom: solid 2px #67baca;
						}
						&:hover {
							color: #67baca;
							border-bottom: solid 2px #67baca;
						}
					}
					.urbanism {
						&.activate {
							color: #8a5d3b;
							border-bottom: solid 2px #8a5d3b;
						}
						&:hover {
							color: #8a5d3b;
							border-bottom: solid 2px #8a5d3b;
						}
					}
				}
			}
			a {
				color: #080b0b;
				text-decoration: none;
				&:hover {
					color: #286483;
				}
			}
			.active {
				color: #286483;
				position: relative;
				&::after {
					content: " ";
					width: 23px;
					height: 15px;
					background-color: #286483;
					color: red;
					font-weight: bold;
					position: absolute;
					right: -38px;
					top: 7px;
				}
			}
		}
		.nav-area.white-mode {
			a {
				color: white;
			}
			button {
				color: white;
			}
		}

		@media (max-width: 450px) {
			.nav-area {
				font-size: 20px;
				button {
					font-size: 20px;
				}
				li {
					margin-bottom: 15px;
					.sub-nav {
						margin: 20px 0;
						font-weight: 300;
						font-size: 16px;
					}
				}
			}
		}
	}
`;

function HeaderNav({ mode, color }) {
	let location = useLocation();
	let navigate = useNavigate();
	let param = useParams();
	const [active, setActive] = useState();
	const [projectsToggle, setProjectsToggle] = useState();
	const [selectedParam, setSelectedParam] = useState();
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	useEffect(() => {
		setActive(getPath(location.pathname));

		if (active === "projects") {
			setProjectsToggle(true);
		}
	}, [location, active]);
	useEffect(() => {
		if (active === "projects") {
			setSelectedParam(param.category);
		}
	}, [param]);
	return (
		<StyledHeaderNav>
			<List onClick={handleShow} className="menu-icon" />

			<Offcanvas
				show={show}
				onHide={handleClose}
				placement="end"
				style={{ maxWidth: "260px" }}
			>
				<Offcanvas.Header closeButton></Offcanvas.Header>
				<StyledHeaderNav>
					<Row>
						<Col>
							<Link to="/">
								<Image
									className="logo"
									src="/ODA-logo.png"
									alt="One Dream Architects"
								/>
							</Link>
						</Col>
					</Row>
					<Row>
						<Col>
							<ul className={`nav-area`}>
								<li>
									<button
										onClick={() =>
											setProjectsToggle(!projectsToggle)
										}
										aria-controls="example-collapse-text"
										aria-expanded={projectsToggle}
										style={{
											color: "black",
										}}
									>
										<span
											className={
												active === "projects" &&
												"active"
											}
										>
											Projects
										</span>
									</button>
									<Collapse in={projectsToggle}>
										<ul className="sub-nav">
											<li>
												<Link
													to="/projects/all"
													className={`
												${selectedParam && selectedParam === "all" ? "activate" : ""} all`}
												>
													All
												</Link>
											</li>
											<li>
												<Link
													to="/projects/housing"
													className={`
												${selectedParam && selectedParam === "housing" ? "activate" : ""} housing`}
												>
													Housing
												</Link>
											</li>
											<li>
												<Link
													to="/projects/commercial"
													className={`
												${
													selectedParam &&
													selectedParam ===
														"commercial"
														? "activate"
														: ""
												} commercial`}
												>
													Commercial
												</Link>
											</li>
											<li>
												<Link
													to="/projects/urbanism"
													className={`
												${selectedParam && selectedParam === "urbanism" ? "activate" : ""} urbanism`}
												>
													Urbanism
												</Link>
											</li>
											<li>
												<Link
													to="/projects/public_space"
													className={`
												${
													selectedParam &&
													selectedParam ===
														"public_space"
														? "activate"
														: ""
												} public_space`}
												>
													Public Space
												</Link>
											</li>
											<li>
												<Link
													to="/projects/public_building"
													className={`
												${
													selectedParam &&
													selectedParam ===
														"public_building"
														? "activate"
														: ""
												} public_building`}
												>
													Public Building
												</Link>
											</li>
										</ul>
									</Collapse>
								</li>
								<li>
									<Link to="/teams">
										<span
											className={
												active === "team" && "active"
											}
										>
											Team
										</span>
									</Link>
								</li>
								<li>
									<Link to="/about">
										<span
											className={
												active === "about" && "active"
											}
										>
											About
										</span>
									</Link>
								</li>
								<li>
									<Link to="/contact">
										<span
											className={
												active === "contact" && "active"
											}
										>
											Contact
										</span>
									</Link>
								</li>
								<li>
									<a
										href="https://www.instagram.com/onedream.architects/"
										target="_blank"
										style={{
											marginRight: "15px",
											fontSize: "20px",
											color: "black",
										}}
									>
										<Instagram />
									</a>
									<a
										href="mailto:contact@onedreamarchitects.com"
										target="_blank"
									>
										<Envelope />
									</a>
								</li>
							</ul>
						</Col>
					</Row>
				</StyledHeaderNav>
			</Offcanvas>
		</StyledHeaderNav>
	);
}

export default HeaderNav;

import React, { useEffect, useState, Fragment } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import { StyledTeamPage } from "./index.style";
import { Card } from "./components/Card";
import Footer from "../../components/footer";
import HeaderNav from "../../components/headerNav";
import { API_SERVER, API_SERVER_PLAIN } from "../../config/constants";
import axios from "axios";

function Team() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		handleGetPeoples();
	}, []);
	useEffect(() => {
		if (data) {
			setLoading(false);
		}
	}, [data]);

	const handleGetPeoples = () => {
		setLoading(true);
		try {
			axios
				.get(API_SERVER + "peoples")
				.then((response) => {
					if (response.data.success) {
						setData(response.data.data);
					} else {
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		} catch (err) {}
	};

	return (
		<Fragment>
			<HeaderNav />
			<StyledTeamPage className="wrapper">
				<Container>
					<Row>
						<Col>
							<h3>One Dream Architects</h3>
							<h1>One dream, Thousand approaches.</h1>
						</Col>
					</Row>
					<Row>
						<Col md={8} className="head">
							<p>
								Our team has an educational background in
								architecture, urban design, civil engineering,
								graphic design, and other expertise. We believe
								that collaboration is an important thing in the
								design practice. So we're still growing and
								looking for other experts, to learn and make the
								dream come true.
							</p>
						</Col>
						<Col md={6}></Col>
					</Row>
					{loading && !data ? (
						<div style={{ width: "100%", textAlign: "center" }}>
							<Spinner animation="border" size="md" style={{}} />
						</div>
					) : (
						<Row>
							{data &&
								data.map((e) => (
									<Col xs={6} sm={6} md={3} className="mb-5">
										<Card
											name={e.name}
											title={e.title}
											url={`${API_SERVER_PLAIN}${e.image}`}
										/>
									</Col>
								))}
						</Row>
					)}
				</Container>
			</StyledTeamPage>
			<Footer />
		</Fragment>
	);
}

export default Team;

import React, { useEffect } from "react";
import styled from "styled-components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { API_SERVER_PLAIN } from "../../../config/constants";

export const StyledProjectCard = styled.div`
	&& {
		width: 100%;
		height: 100%;
		position: relative;
		min-height: 260px;
		background: #2e2e2e;
		cursor: pointer;
		&:hover .overlay {
			opacity: 0.8;
		}
		.image {
			display: block;
			width: 100%;
			min-height: 260px;
			object-fit: cover;
			object-position: center;
		}
		.overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			transition: 0.5s ease;
			&.housing {
				background-color: #eca924;
			}
			&.commercial {
				background-color: #ac49c4;
			}
			&.public_space {
				background-color: #b3c057;
			}
			&.public_building {
				background-color: #67baca;
			}
			&.urbanism {
				background-color: #8a5d3b;
			}
		}
		.text {
			margin: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: 256px;
			width: 100%;
			transform: translate(-50%, -50%);
			text-align: center;
			color: white;
			h3 {
				text-transform: uppercase;
				font-weight: 500;
			}
		}
	}
`;

export const ProjectCard = ({
	title,
	thumbnail,
	location,
	category,
	...props
}) => {
	return (
		<StyledProjectCard>
			{thumbnail && (
				<Image
					src={`${API_SERVER_PLAIN}${thumbnail}`}
					className="image"
				/>
			)}
			<div className={`overlay ${category}`}>
				<div className="text">
					<h3>{title}</h3>
					<p style={{ textTransform: "uppercase" }}>{location}</p>
				</div>
			</div>
		</StyledProjectCard>
	);
};

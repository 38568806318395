import styled from "styled-components";

export const StyledContactPage = styled.div`
	&& {
		h1 {
			margin-bottom: 100px;
		}
		h3 {
			margin-bottom: 30px;
		}
		.submit {
			font-size: 24px;
			padding: 7px 25px;
			border: none;
			background: #67b9c9;
			border-radius: 4px;
		}
		.reach {
			font-weight: 500;
			font-size: 21px;
			margin-top: 45px;
		}
	}
`;

import styled from "styled-components";

export const StyledAboutPage = styled.div`
	&& {
		margin-top: 0;
		.image {
			text-align: center;
			img {
				height: 100vh;
				width: 100%;
				object-fit: cover;
			}
		}
		.word {
			position: relative;
			p {
				position: absolute;
				top: 50%;
				font-size: 25px;
				transform: translateY(-50%);
			}
		}

		@media (max-width: 450px) {
			.image {
				img {
					object-fit: contain;
				}
			}
			.word {
				p {
					font-size: 18px;
				}
			}
		}
	}
`;

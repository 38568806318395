import React from "react";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ScrollToTop from "react-scroll-to-top";
import styled from "styled-components";

export const StyledFooter = styled.div`
	&& {
		box-shadow: 0 1px 6px 0 var(--color-shadow, rgba(49, 53, 59, 0.12));
		padding-top: 20px;
		img {
			width: 275px;
			margin-left: 75px;
			margin-top: 25px;
		}
		.nav-list {
			text-align: right;
			padding-right: 20px;
			h4 {
				text-decoration: underline;
			}
			ul {
				list-style-type: none;
				li {
					margin-bottom: 5px;
				}
				a {
					font-size: 19px;
					font-weight: 500;
					color: #080b0b;
					text-decoration: none;
				}
			}
		}
		@media (max-width: 1024px) {
			margin-bottom: 0px;
			img {
				margin-left: 0px;
				margin-top: 25px;
			}
			.nav-list {
				padding-right: 0px;
			}
		}
		@media (max-width: 570px) {
			img {
				margin-top: 0px;
				width: 185px;
			}
			.nav-list {
				h4 {
					font-size: 15px;
				}
				ul {
					a {
						font-size: 14px;
					}
				}
			}
		}
	}
`;

function Footer() {
	const year = new Date().getFullYear();
	return (
		<StyledFooter>
			<ScrollToTop
				smooth
				style={{ right: "50%", translateX: "-50%", bottom: "80px" }}
			/>
			<Container fluid>
				<Row>
					<Col xs={6}>
						<Image src="/footer-logo.png" />
					</Col>
					<Col xs={6} style={{ textAlign: "right" }}>
						<div className="nav-list">
							<ul>
								<li>
									<h4>BSD City, Serpong</h4>
								</li>
								<li>
									<Link to={`/projects`}>Projects</Link>
								</li>
								<li>
									<Link to={`/about`}>About</Link>
								</li>
								<li>
									<Link to={`/contact`}>Contact</Link>
								</li>
								<li>
									<p style={{ marginTop: "10px" }}>
										{year} One Dream Architects
									</p>
								</li>
							</ul>
						</div>
					</Col>
				</Row>
			</Container>
		</StyledFooter>
	);
}

export default Footer;

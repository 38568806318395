import React, { Fragment, useState } from "react";
import { Image } from "semantic-ui-react";
import Slider from "react-slick";
import { ArrowRight } from "react-bootstrap-icons";
import { Animated } from "react-animated-css";

import MainLogo from "../../assets/LOGO PUTIH-12.png";
import SlideImage2 from "../../assets/PUBLIC SPACE.png";
import SlideImage3 from "../../assets/URBANISM-2.jpg";
import SlideImage4 from "../../assets/HOUSING.png";
import SlideImage5 from "../../assets/COMMERCIAL.png";
import SlideImage6 from "../../assets/PUBLIC BUILDING-2.jpg";

import HeaderNav from "../../components/headerNav";
import { StyledHomePage } from "./index.style";
import { Link } from "react-router-dom";

const Home = () => {
	const [active, setActive] = useState(0);

	const settings = {
		speed: 1500,
		fade: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		lazyLoad: false,
		pauseOnHover: false,
		afterChange: (current) => setActive(current),
	};
	return (
		<Fragment>
			<StyledHomePage>
				<HeaderNav mode="home" color="white" />
				<Image src={MainLogo} className="main-logo" />
				<Slider
					{...settings}
					className="slider-main"
					style={{
						position: "fixed",
						width: "100%",
						height: "100%",
						top: "0",
						zIndex: "-1",
					}}
				>
					<div className="slide-container">
						<div className="main-banner" />
						<Image src="/ODA-logo-front.svg" className="logo" />
						<Animated animationIn="fadeIn" isVisible={active === 0}>
							<div className="detail slide-1">
								<div className="detail-header">
									<h5>About</h5>
								</div>
								<div className="detail-tag">
									<p>
										one dream,
										<br />
										thousand
										<br />
										approaches
									</p>
								</div>
								<Link to="/about">
									<ArrowRight
										style={{
											fontSize: "60px",
											float: "right",
											position: "absolute",
											right: "20px",
											bottom: "25px",
										}}
									/>
								</Link>
							</div>
						</Animated>
					</div>
					<div className="slide-container">
						<Image
							src={SlideImage2}
							alt="Public Space project ODA"
							className="slide-image"
						/>
						<Image src="/ODA-logo-front.svg" className="logo" />
						<Animated animationIn="fadeIn" isVisible={active === 1}>
							<div className="detail slide-2">
								<div className="detail-header">
									<h5>Projects:</h5>
									<p>Public Space</p>
								</div>
								<div className="detail-tag">
									<p>
										'public place for
										<br />a better planet'
									</p>
								</div>
								<Link to="/projects/public_space">
									<ArrowRight
										style={{
											fontSize: "35px",
											float: "right",
										}}
									/>
								</Link>
							</div>
						</Animated>
					</div>
					<div className="slide-container">
						<Image
							src={SlideImage3}
							alt="Urbanism project ODA"
							className="slide-image"
						/>
						<Image src="/ODA-logo-front.svg" className="logo" />
						<Animated animationIn="fadeIn" isVisible={active === 2}>
							<div className="detail slide-3">
								<div className="detail-header">
									<h5>Projects:</h5>
									<p>Urbanism</p>
								</div>
								<div className="detail-tag">
									<p>
										'smallest scale to
										<br />
										the largest one'
									</p>
								</div>
								<Link to="/projects/urbanism">
									<ArrowRight
										style={{
											fontSize: "35px",
											float: "right",
										}}
									/>
								</Link>
							</div>
						</Animated>
					</div>
					<div className="slide-container">
						<Image
							src={SlideImage4}
							alt="Housing project ODA"
							className="slide-image"
						/>
						<Image src="/ODA-logo-front.svg" className="logo" />
						<Animated animationIn="fadeIn" isVisible={active === 3}>
							<div className="detail slide-4">
								<div className="detail-header">
									<h5>Projects:</h5>
									<p>Housing</p>
								</div>
								<div className="detail-tag">
									<p>
										'design better
										<br />
										housing for the
										<br />
										people'
									</p>
								</div>
								<Link to="/projects/housing">
									<ArrowRight
										style={{
											fontSize: "35px",
											float: "right",
										}}
									/>
								</Link>
							</div>
						</Animated>
					</div>
					<div className="slide-container">
						<Image
							src={SlideImage5}
							alt="Commercial project ODA"
							className="slide-image"
						/>
						<Image src="/ODA-logo-front.svg" className="logo" />
						<Animated animationIn="fadeIn" isVisible={active === 4}>
							<div className="detail slide-5">
								<div className="detail-header">
									<h5>Projects:</h5>
									<p>Commercial</p>
								</div>
								<div className="detail-tag">
									<p>
										'good design is
										<br />
										good business'
									</p>
								</div>
								<Link to="/projects/commercial">
									<ArrowRight
										style={{
											fontSize: "35px",
											float: "right",
										}}
									/>
								</Link>
							</div>
						</Animated>
					</div>
					<div className="slide-container">
						<Image
							src={SlideImage6}
							alt="Public Building project ODA"
							className="slide-image"
						/>
						<Image src="/ODA-logo-front.svg" className="logo" />
						<Animated animationIn="fadeIn" isVisible={active === 5}>
							<div className="detail slide-6">
								<div className="detail-header">
									<h5>Projects:</h5>
									<p>Public Building</p>
								</div>
								<div className="detail-tag">
									<p>
										'building for
										<br />
										better cities'
									</p>
								</div>
								<Link to="/projects/public_building">
									<ArrowRight
										style={{
											fontSize: "35px",
											float: "right",
										}}
									/>
								</Link>
							</div>
						</Animated>
					</div>
				</Slider>
				<div className="slider-mobile">
					<div className="main-banner" />
					<Image src="/ODA-logo-front.svg" className="logo" />
					{/* <div className="detail">
						<div className="detail-header">
							<h5>About</h5>
						</div>
						<div className="detail-tag">
							<p>
								one dream,
								<br />
								thousand
								<br />
								approaches
							</p>
						</div>
						<Link to="/about">
							<ArrowRight
								style={{
									fontSize: "60px",
									float: "right",
									position: "absolute",
									right: "20px",
									bottom: "25px",
								}}
							/>
						</Link>
					</div> */}
				</div>
			</StyledHomePage>
		</Fragment>
	);
};
export default Home;

import styled from "styled-components";

export const StyledProjectsPage = styled.div`
	&& {
		.tab-content {
			min-height: 852px;
		}
		.nav {
			margin: 0;
			margin-bottom: 50px;
			a {
				color: #080b0b;
				font-weight: 500;
				font-size: 20px;
				cursor: pointer;
			}
		}
		.nav-link.active {
		}
		.all.activate {
			a {
				color: #2e6783;
			}

			border-bottom: solid 2px #2e6783;
		}
		.housing.activate {
			a {
				color: #eca924;
			}
			border-bottom: solid 2px #eca924;
		}
		.commercial.activate {
			a {
				color: #ac49c4;
			}
			border-bottom: solid 2px #ac49c4;
		}
		.public_space.activate {
			a {
				color: #b3c057;
			}
			border-bottom: solid 2px #b3c057;
		}
		.public_building.activate {
			a {
				color: #67baca;
			}
			border-bottom: solid 2px #67baca;
		}
		.urbanism.activate {
			a {
				color: #8a5d3b;
			}
			border-bottom: solid 2px #8a5d3b;
		}
		@media (max-width: 450px) {
			.tab-content {
				min-height: auto;
			}
			.nav {
				a {
					font-size: 16px;
				}
			}
		}
	}
`;

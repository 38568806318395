import styled from "styled-components";

export const StyledProjectList = styled.div`
	&& {
		.list-card {
			background: red;
			width: 100%;
			height: 100%;
			max-height: 400px;
		}
		.page-item.active .page-link {
			color: white;
		}
		.page-link {
			color: #080b0b;
		}
	}
`;

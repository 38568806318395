import Home from "../pages/Home";
import Projects from "../pages/Projects";
import Details from "../pages/Details";
import About from "../pages/About";
import Team from "../pages/Team";
import Contact from "../pages/Contact";

export const elementsSchema = [
	{
		path: "/",
		element: <Home />,
	},
	{
		path: "/projects",
		element: <Projects />,
		children: [
			{
				path: ":category",
			},
		],
	},
	{
		path: "/details",
		element: <Details />,
		children: [
			{
				path: ":slug",
			},
		],
	},
	{
		path: "/about",
		element: <About />,
	},
	{
		path: "/teams",
		element: <Team />,
	},
	{
		path: "/contact",
		element: <Contact />,
	},
];

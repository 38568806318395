import styled from "styled-components";

export const StyledTeamPage = styled.div`
	&& {
		h1 {
			margin-bottom: 65px;
		}
		h3 {
			margin-bottom: 30px;
		}
		.head {
			p {
				text-align: justify;
				margin-bottom: 85px;
			}
		}
	}
`;

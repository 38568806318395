import React, { useEffect, useState, Fragment } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";

import { Link, useParams } from "react-router-dom";
import { StyledDetailsPage } from "./index.style";
import Footer from "../../components/footer";
import HeaderNav from "../../components/headerNav";
import { API_SERVER, API_SERVER_PLAIN } from "../../config/constants";
import axios from "axios";
import DetailSlides from "./components/slides";

const categoryDictionary = {
	housing: "Housing",
	commercial: "Commercial",
	urbanism: "Urbanism",
	public_space: "Public Space",
	public_building: "Public Building",
};

function Details() {
	let { slug } = useParams();

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (slug) {
			handleGetProject();
			window.scrollTo(0, 0);
		}
	}, [slug]);
	useEffect(() => {
		if (data) {
			setLoading(false);
		}
	}, [data]);

	const handleGetProject = () => {
		setLoading(true);
		try {
			axios
				.get(API_SERVER + "projects/" + slug)
				.then((response) => {
					if (response.data.success) {
						setData(response.data.data);
					} else {
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		} catch (err) {}
	};

	return (
		<Fragment>
			<HeaderNav />
			{loading && !data ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					<Spinner animation="border" size="md" style={{}} />
				</div>
			) : (
				<StyledDetailsPage>
					<Image
						className="banner"
						src={`${API_SERVER_PLAIN}${data?.banner}`}
						fluid
					/>
					{data && (
						<Container>
							<Row>
								<Col xs={12} md={6}>
									<h1>{data.title}</h1>
									<Link to={`/projects/${data.category}`}>
										<h3>
											{categoryDictionary[data.category]}
										</h3>
									</Link>
									<div className="mt-4 mb-4 spec">
										<Row>
											<Col xs={3}>Program</Col>
											<Col xs={9}>: {data.program}</Col>
										</Row>
										<Row>
											<Col xs={3}>Status</Col>
											<Col xs={9}>: {data.status}</Col>
										</Row>
										<Row>
											<Col xs={3}>Location</Col>
											<Col xs={9}>: {data.location}</Col>
										</Row>
										<Row>
											<Col xs={3}>Size</Col>
											<Col xs={9}>: {data.size} sqm</Col>
										</Row>
										<Row>
											<Col xs={3}>Year</Col>
											<Col xs={9}>: {data.year}</Col>
										</Row>
										<Row>
											<Col xs={3}>Partner</Col>
											<Col xs={9}>: {data.partner}</Col>
										</Row>
									</div>
								</Col>
								<Col xs={12} md={6}>
									<article>{data.description}</article>
								</Col>
							</Row>
							<Row>
								<Col>
									<DetailSlides id={data?.id} />
								</Col>
							</Row>
						</Container>
					)}
				</StyledDetailsPage>
			)}
			<Footer />
		</Fragment>
	);
}

export default Details;

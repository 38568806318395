import React from "react";
import { Grid, Header } from "semantic-ui-react";
import styled from "styled-components";

const { Column } = Grid;

export const StyledHeader = styled(Header)`
	& {
		font-family: Poppins-Regular;
	}
`;

const Page500 = () => {
	return (
		<Grid container>
			<Column textAlign="center">
				<StyledHeader as="h1">500</StyledHeader>
				<StyledHeader as="h1">Internal Server Error</StyledHeader>
			</Column>
		</Grid>
	);
};

export default Page500;

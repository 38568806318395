import React from "react";
import styled from "styled-components";

export const StyledInputTextArea = styled.div`
	&& {
		height: 154px;
		position: relative;
		margin-bottom: 0px;
		padding-bottom: 23px;
		textarea {
			height: 100%;
			padding: 18px;
			border-radius: 6px;
			border: solid 2px;
			width: 100%;
			resize: none;
		}
		.error-input {
			border-color: red;
		}
		p {
			position: absolute;
			color: red;
			margin: 0;
		}
	}
`;

export const InputTextAreaFormik = ({
	name,
	placeholder,
	formik,
	...props
}) => {
	return (
		<StyledInputTextArea>
			<textarea
				id={name}
				name={name}
				onChange={formik.handleChange}
				placeholder={placeholder}
				{...props}
				className={
					formik &&
					formik.errors &&
					formik.errors[name] &&
					"error-input"
				}
			/>
			{formik && formik.errors && formik.errors[name] && (
				<p>{formik.errors[name]}</p>
			)}
		</StyledInputTextArea>
	);
};

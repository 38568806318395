import styled from "styled-components";

export const StyledHomePage = styled.div`
	&& {
		background: none;
		.main-logo {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 20%;
		}
		.main-banner {
			width: 100%;
			height: 100vh;
			z-index: 0;
			background: #68b9ca;
		}
		.slider-main {
			.slide-image {
				width: 100%;
				height: 100vh;
				object-fit: cover;
				object-position: center center;
			}
			.logo {
				width: 100%;
				height: 100vh;
				object-fit: cover;
				object-position: center center;
				z-index: 0;
				transform: scale(1.6);
				position: absolute;
				top: -62px;
			}

			.slide-container {
				position: relative;
				.detail {
					transform: translateY(-50%);
					transform: translateX(-50%);
					position: absolute;
					color: white;
					padding: 20px;
					width: 320px;
					&.slide-1 {
						background: #2e6784;
						width: 360px;
						left: 75%;
						top: 20.1%;
						.detail-header {
							h5 {
								font-size: 18px;
							}
							p {
								font-size: 15px;
							}
						}
						.detail-tag {
							p {
								font-size: 32px;
							}
						}
					}
					&.slide-2 {
						background: #b3c057;
						left: 79%;
						top: 52.1%;
					}
					&.slide-3 {
						background: #8a5e3b;
						left: 23%;
						top: 44.1%;
					}
					&.slide-4 {
						background: #d89628;
						left: 79%;
						top: 44.1%;
					}
					&.slide-5 {
						background: #92278f;
						left: 79%;
						top: 40%;
					}
					&.slide-6 {
						background: #23a8e1;
						left: 19%;
						top: 41%;
					}

					a {
						color: white;
					}
					.detail-header {
						text-align: right;
						h5 {
							margin-bottom: 0;
							line-height: 1;
							font-size: 18px;
						}
						p {
							font-size: 15px;
						}
					}
					.detail-tag {
						p {
							font-size: 28px;
							font-weight: 400;
						}
					}
				}
			}
		}
		.slider-mobile {
			display: none;
		}
	}
	@media (max-width: 1024px) {
		&& {
			.main-logo {
				position: fixed;
				bottom: 0;
				left: 0;
				width: 333px;
			}
			.slider-main {
				display: none;
			}
			.slider-mobile {
				display: block;
				.logo {
					width: 100%;
					height: 100vh;
					object-fit: cover;
					object-position: center center;
					z-index: 0;
					transform: scale(1.6);
					position: fixed;
					top: -62px;
					left: 25px;
				}
				.detail {
					transform: translateY(-50%);
					transform: translateX(-50%);
					position: absolute;
					color: white;
					padding: 20px;
					background: #2e6784;
					left: 75%;
					top: 20.1%;
					.detail-header {
						h5 {
							font-size: 18px;
						}
						p {
							font-size: 15px;
						}
					}
					.detail-tag {
						p {
							font-size: 32px;
						}
					}
				}
			}
		}
	}
	@media (max-width: 500px) {
		&& {
			.main-logo {
				width: 235px;
			}
		}
	}
`;

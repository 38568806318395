import React from "react";
import { useRoutes } from "react-router-dom";
import { elementsSchema } from "./schema";

const PublicRoutes = () => {
	let routes = useRoutes(elementsSchema);
	return routes;
};

export { PublicRoutes };

import React, { useEffect, useState, Fragment } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import ReactFullpage from "@fullpage/react-fullpage";
import { StyledAboutPage } from "./index.style";

import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/footer";
import HeaderNav from "../../components/headerNav";

function About() {
	useEffect(() => {}, []);

	return (
		<Fragment>
			<HeaderNav />
			<StyledAboutPage className="wrapper">
				<Container fluid="md">
					<ReactFullpage
						//fullpage options
						licenseKey={"YOUR_KEY_HERE"}
						scrollingSpeed={1000} /* Options here */
						render={({ state, fullpageApi }) => {
							return (
								<ReactFullpage.Wrapper>
									<div className="section">
										<Row>
											<Col
												xs={6}
												md={6}
												className="word align-middle"
											>
												<p>
													<b>We are ODA</b>, we design
													and create architecture
													spaces for Housing,
													Commercial, Urbanism, Public
													Space, Public Building
												</p>
											</Col>
											<Col
												xs={6}
												md={6}
												className="image"
											>
												<Image src="/icons/About-28.png" />
											</Col>
										</Row>
									</div>
									<div className="section">
										<Row>
											<Col
												xs={6}
												md={6}
												className="word align-middle"
											>
												<p>
													We are group of designers
													with different <br />
													<b>
														backgrounds, perceptions
													</b>{" "}
													and <b>purposes.</b>
												</p>
											</Col>
											<Col
												xs={6}
												md={6}
												className="image"
											>
												<Image src="/icons/About-29.png" />
											</Col>
										</Row>
									</div>
									<div className="section">
										<Row>
											<Col
												xs={6}
												md={6}
												className="word align-middle"
											>
												<p>
													But we have <b>one dream</b>
													<br /> that connects all of
													us.
												</p>
											</Col>
											<Col
												xs={6}
												md={6}
												className="image"
											>
												<Image src="/icons/About-30.png" />
											</Col>
										</Row>
									</div>
									<div className="section">
										<Row>
											<Col
												xs={6}
												md={6}
												className="word align-middle"
											>
												<p>
													This <b>one dream </b>bring
													us to better future.
												</p>
											</Col>
											<Col
												xs={6}
												md={6}
												className="image"
											>
												<Image src="/icons/About-31.png" />
											</Col>
										</Row>
									</div>
									<div className="section">
										<Row>
											<Col
												xs={6}
												md={6}
												className="word align-middle"
											>
												<p>
													What dream? we dream to
													create a better <br />
													<b>
														people, planet,
													</b> and <b>profit </b>{" "}
													through architecture and
													urban design
												</p>
											</Col>
											<Col
												xs={6}
												md={6}
												className="image"
											>
												<Image src="/icons/About-32.png" />
											</Col>
										</Row>
									</div>
									<div className="section">
										<Row>
											<Col
												xs={6}
												md={6}
												className="word align-middle"
											>
												<p>
													With{" "}
													<b>thousand approaches</b>{" "}
													<br />
													let's design that dream
													together
												</p>
											</Col>
											<Col
												xs={6}
												md={6}
												className="image"
											>
												<Image src="/icons/About-33.png" />
											</Col>
										</Row>
									</div>
								</ReactFullpage.Wrapper>
							);
						}}
					/>
				</Container>
			</StyledAboutPage>
			<Footer />
		</Fragment>
	);
}

export default About;

import React from "react";
import { Grid, Header } from "semantic-ui-react";
import styled from "styled-components";

const { Column } = Grid;
export const StyledHeader = styled(Header)`
	& {
		font-family: Poppins-Regular;
	}
`;

const Page404 = () => {
	return (
		<Grid container>
			<Column textAlign="center">
				<StyledHeader as="h1">Page Not Found</StyledHeader>
			</Column>
		</Grid>
	);
};

export default Page404;

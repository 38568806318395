import styled from "styled-components";

export const StyledDetailsPage = styled.div`
	&& {
		margin-bottom: 200px;
		display: block;
		a {
			text-decoration: none;
			color: gray;
		}
		h3 {
			text-transform: capitalize;
		}
		article {
			white-space: pre-wrap;
			text-align: justify;
		}
		.banner {
			margin-bottom: 100px;
			width: 100%;
		}
		.spec {
			font-weight: 500;
			padding-right: 82px;

			.row {
				border-bottom: solid 2px #a3a3a3;
				margin-bottom: 10px;
				padding-bottom: 5px;
			}
		}
	}
`;
